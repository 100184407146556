import { defineStore } from "pinia";

import { Meta, ToastType } from "~/types/general";
import { useTasksStore } from "~/store/tasks";

export const useManagementBrandsStore = defineStore("management-brands", () => {
  // State
  const managementBrands = ref([]);
  const selectedRowsIds = ref([]);
  const baseTableKey = ref(0);
  const totalManagementBrands = ref(null);
  const totalPages = ref(null);
  const isOpenSidebar = ref(false);
  const meta = ref({} as Meta);
  const filters = ref({
    letter: "",
  });
  const pagination = ref({
    page: 1,
    perPage: 40,
  });
  const pageTable = ref(1);
  const perPageTable = ref(10);
  const filtersOptions = ref({
    groups: {
      type: "groups",
      title: "Brand Group",
      subtitle: "Leave blank to view all statuses",
      options: [],
      chosenOptions: [],
    },
    tags: {
      type: "tags",
      title: "Brand Tag",
      subtitle: "Leave blank to view all statuses",
      options: [],
      chosenOptions: [],
    },
    status: {
      type: "status",
      title: "Brand Status",
      subtitle: "Leave blank to view all statuses",
      options: [
        { name: "Declined", value: "declined" },
        { name: "Pending", value: "pending" },
        { name: "Active", value: "active" },
      ],
      chosenOptions: [],
    },
  });
  const storeTags = ref([]);
  const storeCategories = ref([]);

  // Get Management Brands
  async function getManagementBrands(payload?) {
    let response;
    const params = { ...payload };

    try {
      response = await useVaniloApi("/brands", { params });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    managementBrands.value =
      response.meta.current_page === 1
        ? response?.data
        : [...managementBrands.value, ...response?.data];

    pageTable.value = response.meta.current_page;
    totalManagementBrands.value = response.meta.total;
    totalPages.value = response.meta.last_page;

    return response;
  }

  // Import Management Brands
  async function importManagementBrands(body?) {
    try {
      const response = await useVaniloApi("/brands/import", {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useToast(`${error}`, {
        type: ToastType.ERROR,
        duration: 3000,
      });
      console.log(error);
    }
  }

  // Export Management Brands
  async function exportManagementBrands(params?) {
    try {
      const response = await useVaniloApi("/brands/export", {
        params,
      });

      return response;
    } catch (error) {
      useToast(`${error}`, {
        type: ToastType.ERROR,
        duration: 3000,
      });
      console.log(error);
    }
  }

  // Bulk Update Management Brands
  async function bulkUpdateManagementBrands(brandsIds, updatesValue) {
    let response;
    let payload = {};
    const tasksStore = useTasksStore();

    if (updatesValue.type === "change-status") {
      payload["status"] = updatesValue.value;
    }
    if (
      updatesValue.type === "update-tag" ||
      updatesValue.type === "assign-category"
    ) {
      payload["taxonomies"] = updatesValue.value;
    }

    try {
      response = await useVaniloApi(`/brands/bulk-update`, {
        method: "POST",
        body: {
          ...(brandsIds.length && { brands: brandsIds }),
          ...payload,
        },
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    tasksStore.taskId = response.data.id;

    return response;
  }

  // Bulk Notify Management Brands
  async function bulkNotifyManagementBrands(payload) {
    let response;

    try {
      response = await useVaniloApi(`/brands/bulk-notify`, {
        method: "POST",
        body: payload,
      });
    } catch (error) {
      console.log(error);
    }

    return response;
  }

  // Bulk Delete Management Brands
  async function bulkDeleteManagementBrands(payload) {
    let response;

    try {
      response = await useVaniloApi(`/brands/bulk-delete`, {
        method: "POST",
        body: payload,
      });
    } catch (error) {
      console.log(error);
    }

    return response;
  }

  return {
    managementBrands,
    selectedRowsIds,
    baseTableKey,
    totalManagementBrands,
    totalPages,
    isOpenSidebar,
    meta,
    filters,
    pagination,
    pageTable,
    perPageTable,
    filtersOptions,
    storeTags,
    storeCategories,
    getManagementBrands,
    importManagementBrands,
    exportManagementBrands,
    bulkUpdateManagementBrands,
    bulkNotifyManagementBrands,
    bulkDeleteManagementBrands,
  };
});
